import React from 'react';
import logo from './web3vm-sitelogo.png';
import './App.css';
import Portis from '@portis/web3';
import Web3 from 'web3';

const portis = new Portis('6047b015-8dab-4f06-ae6a-1ab830e2a749', 'mainnet');
const web3 = new Web3(portis.provider);



 require('events').EventEmitter.defaultMaxListeners = 25

web3.eth.getAccounts((error, accounts) => {
  console.log(accounts);
});

web3.eth.getCoinbase(function(err, result) {if (err) {console.log(err)} else {console.log("This is the account that will receive mining rewards: " + result)}})

/* 
const output = document.getElementById("output")
output.innerHTML = "render this"
 */

web3.eth.getGasPrice(function(err, result) {if (err) {console.log(err)} else {console.log("Current Ethereum Network Gas Price is: " + web3.utils.fromWei(result, "gwei") + " Gwei")}})

web3.eth.getBlock(8000000, function(error, result) {if(!error) console.log("Data for block 8000000: " + JSON.stringify(result)); else console.error(error);})

web3.eth.getBlockNumber(function(err, result) {if (err) {console.log(err)} else {console.log("The current WorldLaw Block Number is: " + result)}})

web3.eth.getBalance("0xFAfCb9F78bED5533692364B4713eD339Aa1e52ca", function(err, result) {if (err) {console.log(err)} else {console.log("The 0xFAfCb9F78bED5533692364B4713eD339Aa1e52ca account has " + web3.utils.fromWei(result, "finney") + " WorldLaw Pawns in the Account, which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(Giga-Wei)")}})

web3.eth.getBlockTransactionCount("0x2d7a13fb9e6a23e933beb5909d77fcde8109f7321242052ff4b72c6a1d8f34fd", function(err, result) {if (err) {console.log(err)} else {console.log("The Ethereum blockchain reports " + result + " Transactions from BLOCKHASH: 0x2d7a13fb9e6a23e933beb5909d77fcde8109f7321242052ff4b72c6a1d8f34fd, TRANSACTION HASH: 0x4eaa6a224c634720f5e78f31306c8657a89c552c61029510b581205a2027841a")}})

web3.eth.getTransaction('0x4eaa6a224c634720f5e78f31306c8657a89c552c61029510b581205a2027841a', function(error, result) {if(!error) console.log("The Transaction Data for Hash 0x4eaa6a224c634720f5e78f31306c8657a89c552c61029510b581205a2027841a is: " + JSON.stringify(result)); else console.error(error);})

web3.eth.getTransactionFromBlock('latest', 1, function(error, result) {if(!error) console.log("Data for Latest Block Index Position 1: " + JSON.stringify(result)); else console.error(error);})

web3.eth.getTransactionCount("0xFAfCb9F78bED5533692364B4713eD339Aa1e52ca", function(err, result) {if (err) {console.log(err)} else {console.log("The R1-MetaMask account with public address: 0xFAfCb9F78bED5533692364B4713eD339Aa1e52ca has executed " + result + " transactions")}})

web3.eth.getBalance("0xFAfCb9F78bED5533692364B4713eD339Aa1e52ca", function(err, result) {if (err) {console.log(err)} else {console.log("The 0xFAfCb9F78bED5533692364B4713eD339Aa1e52ca account has " + web3.utils.fromWei(result, "finney") + " WorldLaw Pawns in the Account, which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})

web3.eth.estimateGas({to: "0x11f4d0A3c12e86B4b5F39B213F7E19D048276DAe",data:"0xc6888fa10000000000000000000000000000000000000000000000000000000000000003"}).then(function (txgas) {console.log("Estimated Ethereum Gas to Run the Data is: " + txgas + " Wei");})

web3.eth.ens.getAddress('regency.eth').then(function (address) {console.log("The Ethereum Hash for Regency.eth is: " + address);})

web3.eth.getBalance("0x00a2Ddfa736214563CEa9AEf5100f2e90c402918", function(err, result) {if (err) {console.log(err)} else {console.log("The Regency.eth Protocol has " + web3.utils.fromWei(result, "finney") + " Finney in the Account, which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})

web3.eth.ens.getAddress('worldlaw.eth').then(function (address) {console.log("Master_Account_WorldLaw.eth " + address);})

web3.eth.getBalance("0x130093A5aEbc07e78e16f0EcEF09d1c45AfD8178", function(err, result) {if (err) {console.log(err)} else {console.log("The WorldLaw.eth Protocol has " + web3.utils.fromWei(result, "finney") + " Finney in the Account, which is the equivalent to " + web3.utils.fromWei(result, "ether") + " Ether, or " + web3.utils.fromWei(result, "gwei") + " Gwei(giga-Wei)")}})

web3.eth.net.getPeerCount(function(err, result) {if (err) {console.log(err)} else {console.log("The WorldLaw Network currently has " + result + " peer nodes operational.")}})



function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo img-fluid50pct" alt="logo" />
        <p>
          Web Site Content Here
        </p>
        <a
          className="App-link"
          href="https://web3vm.com"
          target="_blank"
          rel="noopener noreferrer"
        >

          Web3vm.com
        </a>
      </header>
    </div>
  );
}

export default App;
